body {
    min-height: 75rem;
    padding-top: 4.5rem;
}
.btn {
    border-radius: 20px;
}
.react-datepicker-wrapper {
    display: block !important;
}
.suggestions-list {
    position: absolute;
    top: 100%; /* Поместить список ниже поля ввода */
    left: 0;
    width: 100%; /* Совпадает с шириной поля ввода */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1000;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.suggestion-name {
    font-size: 16px;
    font-weight: bold;
}

.suggestion-details {
    font-size: 14px;
    color: #888; /* Серый цвет для дополнительных данных */
}

.direct-link {
    text-decoration: underline;
    color: #007bff;
    cursor: pointer;
}
